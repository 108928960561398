import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import moment from 'moment';
import React, { useEffect, FC } from 'react';
import styled from 'styled-components';
import App from '../components/App';
import Page from '../components/Page';
import { Menu } from '../components/Menu';
import { Titles } from '../components/Titles';
import { ReactCusdis } from 'react-cusdis';
import writtenNumber from 'written-number';
import { FaClock } from 'react-icons/fa';
import { default as a } from 'indefinite';
import { Boxy } from '../styles/boxy';

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 2em;
`;

const Meta = styled.span`
  opacity: 0.5;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
`;

const ProjectTitles = styled(Titles)``;

const TableOfContentsContainer = styled.div`
  ${Boxy};
  padding: 1em;
  --boxy-color: gray;
  max-width: 75%;
  font-family: var(--header-font);
  font-size: 0.8em;
`;

const PostTitle = styled.h2`
  & a {
    text-decoration: none;
    color: black;
  }
`;

export default function Template({ data }) {
  const { project } = data;
  const nodes = project.fields.posts;
  const timeToReadMinutes = nodes.reduce(
    (minutes, node) => minutes + node.parent.timeToRead,
    0
  );
  let firstPostDate, lastPostDate;
  nodes.forEach((node) => {
    const date = node.rawDate;
    if (date < firstPostDate || !firstPostDate) {
      firstPostDate = date;
    }
    if (date > lastPostDate || !lastPostDate) {
      lastPostDate = date;
    }
  });
  const dateRange = moment(lastPostDate).diff(firstPostDate);

  const postCountText = `This project has ${writtenNumber(nodes.length)} ${
    nodes.length === 1 ? 'post' : 'posts'
  }`;
  const dateRangeText =
    nodes.length !== 1 &&
    `spanning ${moment.duration(dateRange, 'ms').humanize()}`;

  const countAndDateRangeText = [postCountText, dateRangeText]
    .filter((t) => t)
    .join(' ');

  const readingTimeText = `about ${a(
    writtenNumber(timeToReadMinutes)
  )}-minute read`;

  return (
    <App>
      <Menu></Menu>
      <ProjectTitles>
        <h4>Project:</h4>
        <h1>{project.name}</h1>
      </ProjectTitles>
      <Page title={project.name}>
        <TableOfContentsContainer>
          <p>
            {countAndDateRangeText}, {readingTimeText}.
          </p>
          <ol>
            {nodes.map((node) => (
              <li key={node.id}>
                <Link to={`#${node.id}`}>{node.title}</Link>
              </li>
            ))}
          </ol>
        </TableOfContentsContainer>

        <List>
          {nodes.map((node, index) => (
            <li key={node.id}>
              <PostTitle>
                <Link id={node.id} to={node.path}>
                  {node.title}
                </Link>
              </PostTitle>
              <Meta>
                <span>{node.date}</span>
                <span>
                  Post {index + 1} of {nodes.length} in {project.name}
                </span>
              </Meta>
              <MDXRenderer>{node.parent.body}</MDXRenderer>
            </li>
          ))}
        </List>
      </Page>
    </App>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    project(id: { eq: $id }) {
      id
      name
      fields {
        posts {
          id
          slug
          title
          lede
          date(formatString: "D MMMM YYYY")
          rawDate: date
          path: gatsbyPath(filePath: "/{post.slug}")
          parent {
            ... on Mdx {
              body
              timeToRead
            }
          }
        }
      }
    }
  }
`;
