import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

const items = {
  'travisgeis.com': {
    url: '/',
    icon: 'home',
  },
  Contact: {
    url: '/contact',
    icon: 'comments',
  },
};

const MenuContainer = styled.div`
  display: flex;
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  font-family: var(--header-font);
  flex: 1;
  text-align: center;
  padding: 0.5em;
  border-radius: 0.25em;
  color: inherit;
  &:hover {
    background-color: #eee;
  }
`;

export const Menu: FC = () => {
  const contents = Object.entries(items).map(([label, item], index) => {
    const maybeIcon = item.icon && (
      <i className={['icon', item.icon].join(' ')}></i>
    );
    return (
      <MenuLink to={item.url} className="item" key={index}>
        {maybeIcon}
        {label}
      </MenuLink>
    );
  });
  return (
    <MenuContainer id="menu" className="menu">
      {contents}
    </MenuContainer>
  );
};
